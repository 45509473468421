import React from "react";

import {
    Box,
    Typography
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getTranslationConfig, Languages } from "../../../../components/src/helpers";



// Customizable Area End

import SubscriptionbillingController, { Props } from "../SubscriptionbillingController";

export default class SubscriptionBlock extends SubscriptionbillingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { t, dir } = getTranslationConfig();
        const {
            title,
            status,
            currancy,
            price,
            period,
            expiryDate,
            description,
            parsedDescrList,
            imageUrl,
            periodLabel,
            convert_currencies,
            content_types,
            symbol,
            
        } = this.props.subsContent || {};
        const lang = localStorage.getItem('language');


        return (
            <Box
                borderRadius={8}
                style={{
                    backgroundColor: '#FFFFFF',
                    flexBasis: 'calc(50% - 8px)',
                }}
                dir={dir}>
                <Box px={4} pt={2} pb={6} borderRadius={8} style={{
                    backgroundColor: '#D9EEEB',
                    border: '1px solid #B6E6DF'
                }}>
                    <Box>
                        <span style={{ fontFamily: 'Arial', fontSize: '48px', fontWeight: 700 }}>{symbol+price}</span>
                        <span style={{ fontFamily: 'Arial', fontSize: '18px' }}>/{period}</span>
                    </Box>
                    <Box style={{
                        background: `url(${imageUrl}) left/cover`,
                        height: '100px',
                        width: '100%',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end'
                    }}>
                        <Typography
                            style={{
                                color: '#FFFFFF',
                                fontSize: '24px',
                                fontWeight: 700,
                                width: 'fit-content',
                                marginBottom: '12px',
                                textShadow: '0 0 3px #000',
                                textTransform: 'capitalize'
                            }}
                        >
                            {`${title} Membership`}
                        </Typography>
                    </Box>
                </Box>
                <Box mt={2} py={2} px={4}>
                    {status && <Box>
                        <span>{t['subscription-block-status-title']}:{' '}</span><span style={{ color: this.getStatusColor(status) }}>{status}</span>
                    </Box>}

                    {expiryDate && <Box py={4}>
                        <span>{t['subscription-block-expiry-date-title']}:{' '}</span><span style={{ fontWeight: 700 }} dir="ltr">{this.convertExpiryDate(expiryDate)}</span>
                    </Box>}

                    <Box display={"flex"}  flexDirection={'column'} flexWrap={"wrap"} gridRowGap={10} >
                        {content_types?.map((line, index) => (
                            <Box key={index} display={"flex"} alignItems={"center"} gridColumnGap={6} >     
                             <CheckCircleIcon style={{ color: "#34D399"}} />
                             <span >{line.status && (lang === Languages.ar ? line.arabic_content :  line.content)}</span>
                             </Box>
                        ))}
                    </Box>
                    <Box mb={2}>
                        {parsedDescrList?.map((listItem, index) =>
                            <Box key={index} mt={2} display='flex'>
                                <CheckCircleIcon
                                    style={{
                                        color: this.getListBulletColor(listItem.status),
                                        marginRight: '16px'
                                    }} />
                                <span>{listItem.content}</span>
                            </Box>)}
                    </Box>
                    {this.props.subscriptionButtons}
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
