import React from 'react';
import { imgStar } from './assets';
import CurrencyList from "currency-list";
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';
import { defaultLang } from '../../blocks/utilities/src/translations/consts';
import { planExpiry, restrictedSubscriptionId, servicesRestriction } from './consts';

const arLanguage = require('../../blocks/utilities/src/translations/ar.json');
const enLanguage = require('../../blocks/utilities/src/translations/en.json');

export enum Languages {
    en = 'en',
    ar = 'ar'
};

// Check arabic translations in case of change the durations list
export enum Durations {
    fstDur = '30 mins',
    sndDur = '1 hr',
    trdDur = '1 hr 30 mins',
    frthDur = '2 hr'
};

const {
    fstDur,
    sndDur,
    trdDur,
    frthDur
} = Durations;

export const durationList = [
    fstDur,
    sndDur,
    trdDur,
    frthDur
];

export function getStars(avgReview: number | null) {
    return avgReview ? Array.from(Array(Math.round(avgReview))).map((item, index) =>
        <img
            key={index}
            src={imgStar}
            alt='image star'
            width={30}
            height={30}
        />
    ) : ''
}

export const findCurrByCode = (currCode: string) => Object.values(CurrencyList.getAll("en_US")).find(
    (curr: Record<string, string>) => {
        return curr.code === currCode
    }
)?.symbol || '$';

export const getAndSaveCurrency = (responseJson: any) => {
    const currCode = responseJson?.data?.attributes.currency;
    const currancy = findCurrByCode(currCode);
    localStorage.setItem('currency', currancy);

    return currancy;
}

export const createFakeApiMessage = (instanceVar: string, response: Record<string, any>) => {
    const getUserDataEmpImages = new Message(
        getName(MessageEnum.RestAPIResponceMessage)
    );

    getUserDataEmpImages.addData(
        getName(MessageEnum.RestAPIResponceDataMessage),
        getUserDataEmpImages.messageId
    )

    getUserDataEmpImages.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        response
    );
    instanceVar = getUserDataEmpImages.messageId;
    runEngine.sendMessage("Unit test", getUserDataEmpImages);
}

export const getLocalTranslations = (lang: string = defaultLang): { [k: string]: string } => {
    switch (lang) {
        case 'ar':
            return arLanguage;
        default:
            return enLanguage;
    }
};

export const getTranslationConfig = () => {
    const { t } = window as unknown as { t: Record<string, string> };
    const lang = localStorage.getItem('language');

    const isArabic = lang === (Languages.ar as string);
    const dir = isArabic ? 'rtl' : 'ltr';
    const transform = isArabic && { transform: 'rotate(180deg)' };
    const locale = isArabic ? 'ar-EG' : 'en-US';

    return { t, dir, isArabic, lang, transform, locale }
};

/**
 * Returns basic properties from object that has different translation values, extend it for any new property or language
 * @param content 
 * @returns Object<Record<string, string>>
 */

export const getTransContent = (content?: {
    title?: string,
    description?: string,
    name?: string,
    cat_name?: string,
    cat_description?: string,
    average_review_string?: string,
    bio?: string,
    service_name?: string,
    category?: string,
    arabic_title?: string,
    arabic_description?: string,
    arabic_name?: string,
    arabic_cat_name?: string,
    arabic_cat_description?: string,
    arabic_average_review_string?: string,
    arabic_bio?: string,
    arabic_service_name?: string,
    arabic_category?: string,
    ar_title?:string
}): {
    title: string,
    description: string,
    name: string,
    catName: string,
    catDescription: string,
    averageReviewString: string,
    bio: string,
    serviceName: string,
    category: string
} => {
    const lang = localStorage.getItem('language');

    const {
        title = '',
        description = '',
        name = '',
        cat_name = '',
        cat_description = '',
        average_review_string = '',
        bio = '',
        service_name = '',
        category = '',
        arabic_title = '',
        arabic_description = '',
        arabic_name = '',
        arabic_cat_name = '',
        arabic_cat_description = '',
        arabic_average_review_string = '',
        arabic_bio = '',
        arabic_service_name = '',
        arabic_category = '',
        ar_title=''
    } = content || {};

    return lang === Languages.ar ? {
        title: arabic_title || ar_title,
        description: arabic_description,
        name: arabic_name,
        catName: arabic_cat_name,
        catDescription: arabic_cat_description,
        averageReviewString: arabic_average_review_string,
        bio: arabic_bio,
        serviceName: arabic_service_name,
        category: arabic_category
    } : {
        title,
        description,
        name,
        catName: cat_name,
        catDescription: cat_description,
        averageReviewString: average_review_string,
        bio,
        serviceName: service_name,
        category
    }
};

export const getTransDuration = (duration: string): string => {
    const { isArabic } = getTranslationConfig();
    const {
        fstDur,
        sndDur,
        trdDur,
        frthDur
    } = Durations;

    const transTable: Record<string, string> = {
        [fstDur]: 'ثلاثون دقيقة',
        [sndDur]: 'ساعة واحدة',
        [trdDur]: 'ساعة واحدة وثلاثون دقيقة',
        [frthDur]: 'ساعتين'
    };
    return isArabic ? transTable[duration] : duration
}

export const getRtlStyleValue = (isArabic: boolean) => isArabic ? {
    inputPadding: { paddingRight: '32px' },
    avatarMargin: { marginRight: "200px" },
    descriptionJustify: { justifyContent: 'flex-end' },
    phoneTextAlign: { textAlign: 'right', marginRight:"40px", width: '91.5%' },
    flagStyles: { marginRight: '30px'},
    closeIconMargin: { marginRight: 'auto' },
    schedButtonPosition: { left: '24px' },
    elementFlip: { transform: 'rotate(180deg)' },
    selectArrow: { left: '5%' },
    infoMargin: { marginRight: "200px" },
    adHocAlign: { textAlign: 'right'},
    chartAreaPosition: { right: 0 },
    chartAreaLegendPosition: { left: '50px' }
} : {
    avatarMargin: { marginLeft: "200px" },
    closeIconMargin: { marginLeft: 'auto' },
    schedButtonPosition: { right: '32px' },
    selectArrow: { right: '10%' },
    infoMargin: { marginLeft: "200px" },
    adHocAlign: { textAlign: 'left'},
    chartAreaPosition: { left: 0 },
    chartAreaLegendPosition: { right: '50px' }
};

export const selectActiveSubscription = (responseJson: any) =>
    responseJson.data.find((subscription: any) => subscription.status.toLowerCase() === 'active');

export const selectActiveSubscriptionId = (responseJson: any) => selectActiveSubscription(responseJson)?.plan_id;
export const setSubscriptionStateToStorage = (responseJson: any) => {
    const isExisted = Boolean(responseJson.data.length);

    const expiryDate = responseJson?.data[0]?.expiry_date;

    const today = new Date();
    const expiryDateObj = new Date(expiryDate);
    // TODO: Uncomment it and apply for expiry date test purposes only using with previous string value
    // const expiryDateObj = new Date(expiryDateObjInt.setDate(expiryDateObjInt.getDate() - 30));
    const isReviewedPeriod = expiryDateObj < today;

    const thirtyDaysAfterExpiry = new Date(expiryDateObj);
    thirtyDaysAfterExpiry.setDate(thirtyDaysAfterExpiry.getDate() + 30);

   
    const isTrial = isReviewedPeriod && thirtyDaysAfterExpiry >= today;
    const isExpired = isReviewedPeriod && thirtyDaysAfterExpiry < today;

    localStorage.setItem(
        planExpiry,
        JSON.stringify({
            isTrial,
            isExpired,
            isExisted
        }));
};

export const getIsTrial = () => JSON.parse(localStorage.getItem(planExpiry) as string)?.isTrial;
export const getIsExpired = () => JSON.parse(localStorage.getItem(planExpiry) as string)?.isExpired;
export const getIsRestricted = () => getIsTrial() || getIsExpired();

export const applyUserAction = (context: any, expired?: boolean) => {
    const expireCondition = expired ? getIsExpired() : getIsRestricted();

    if (expireCondition) {
        context.setState({ userAction: !context.state.userAction });
    } else {
        return true
    }
}

export const showRestrictedPrompt = (
    currentPlanId: number | null,
    currentServicesNumber: number,
    callBacks: {
        setShowRestrictedPrompt: (arg1: boolean, arg2: Record<string, any>) => void,
        gotoSubscriptionPlans: () => void
    }
): true | undefined => {
        const { t} = getTranslationConfig();
    const {
        setShowRestrictedPrompt,
        gotoSubscriptionPlans
    } = callBacks;

    if (
        currentPlanId === restrictedSubscriptionId &&
        currentServicesNumber === servicesRestriction
    ) {

        setShowRestrictedPrompt(true,
            {
                title: '',
                message: t['current_subscription'],
                confirmText: 'Upgrade Now',
                cancelText: 'Cancel',
                onConfirm: gotoSubscriptionPlans,
            }
        );
        return true
    }
}
