import React from "react";

import {
  Box,
  Button,
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles, TypographyView } from './styles';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Settings1Controller, { Props } from './Settings1Controller';
import { imgConfirm } from './assets';
import { getTranslationConfig } from "../../../components/src/helpers";
// Customizable Area End

export default class SettingsConfirmation extends Settings1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
            const { t } = getTranslationConfig();
    
    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} position='relative' className='landing-venue-block'>
          <AppHeader context={this} />
          <Box className='search-location-block' height='calc(100vh - 56px)' boxSizing='border-box' display='flex' justifyContent='center' alignItems='center' py={12} px='140px'>
            <Box width='fit-content' textAlign='center' position='relative' height='100%' >
              <img src={imgConfirm} alt='subscription plan' />
              <Typography style={{ fontWeight: 700, margin: '48px 0 16px' }}>
               {t["cangratulation_text"]}
              </Typography>
              <Typography style={{ maxWidth: '400px' }}>
              {t["congo_desc"]}
              </Typography>
              <Button
                data-test-id='confirm-go-next-btn'
                type='submit'
                variant="contained"
                color="primary"
                className="agreeButton"
                style={{
                  width: 'auto',
                  fontSize: '16px',
                  padding: '8px 16px',
                  height: '52px',
                  minWidth: '228px',
                  margin: '56px auto auto',
                }}
                endIcon={<ChevronRightRoundedIcon style={{ color: '#ffffff', marginLeft: '8px' }} />}
                onClick={this.goToMyProfile}
              >
              {t["view_btn"]}
              </Button>
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
